import { React, useEffect } from "react";
import './scss/app.scss';
import MainPage from "./Components/MainPage";


function App() {

  return (
    <div>
      <MainPage/>
    </div>
  );
}

export default App;
